.sc-form-edit img {
  max-width: 100%;
}

.entry-download-items {
  display: inline-flex;
  flex-direction: row;
  width: 100%;

  .entry-download-item {
    width: 150px;
    padding: 15px;
    b {
      padding-left: 5px;
      flex: 1 1 100%;
    }
    img {
      width: 100%;
    }
    a {
      height: 100%;
      display: inline-flex;
      position: relative;
      flex-direction: column;
    }
  }
}