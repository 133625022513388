
.sc-tree2 {

  margin: 10px;

  .sc-tree2 {
    margin: 0;
    margin-left: 10px;
  }

  ul {
    border-left: 1px dotted #c5c5c5;
  }

  .folder-line {
    border-bottom: 1px dotted #c5c5c5;
    display: block;
    height: 50%;
    left: -15px;
    position: relative;
    top: 10px;
    width: 10px;
  }

  .tree-icon {
    float: left;
    margin-right: 5px;
  }

  .tree-text {
    display: block;
    float: left;
    min-width: 100px;
    overflow: hidden;
    width: calc( 100% - 20px );
  }

  .tree-entry {
    padding: 3px;
    cursor: pointer;
    position: relative;
  }

  .tree-entry .fa {
    z-index: 10;
  }

  .tree-entry .fa + .fa {
    font-size: 70%;
    left: 8px;
    opacity: 0.5;
    position: absolute;
    top: 2px;
    z-index: 20;
  }

  .tree-entry:hover {
    background-color: #CCCCCC;
  }

  .current > .tree-entry {
    border: 1px dotted #CCCCCC;
  }

  .current > .tree-entry .tree-icon {
    color: #0088CC;
  }

}

.sc-tree {

  width: 250px;
  min-height: 250px;

  li {

    a {

      position: relative;
      display: block;
      margin-bottom: 1px;

      > div {
        position: relative;
        line-height: 20px;
        padding: 5px;
        display: block;
      }

      .folder_status_icon {
        right: 5px;
        top: 5px;
        position: absolute;
        color: #dddddd;
      }

      &.current,
      &:hover {

        > div {
          background-color: #dddddd;

          .folder_status_icon {
            color: #333;
          }
        }

        .folder_status_icon {
          color: #333;
        }

      }

    }

    ul {

      margin-left: 10px;

    }

  }

}

.angular-ui-tree-node {
  margin-top: 10px;
  padding-top: 10px;

  &:first-child {
    border-top: 0;
    padding-top: 0px;
  }
}


.angular-ui-tree-node i.fa-arrows-v {
  padding-right: 10px;
  padding-top: 5px;
  cursor: move;
}

.angular-ui-tree-node input {
  padding: 3px 10px;
  border: 1px solid #ccc;
}

.angular-ui-tree-node .btn {
  margin-top: -2px;
}
