.btn-primary {
  background-color: $primaryColor;
  border-color: $primaryColor;
}

.btn-primary:hover {
  background-color: $primaryColorHover;
  border-color: $primaryColorHover;
}

.btn {
  border-radius: 0;
}

.form-control {
  border-radius: 0;
}

.panel  {

}

.uib-tab {
  margin-bottom: -1px;
}

.tab-content {
  border: 1px solid #ccc;
  border-top: none;
  padding: 10px;
}

.nav-tabs li a {
  border-radius: 0;
}

.table .progress {
  height: auto;
  margin-bottom: 0;
}

input[type=file] {
  height: auto;
}

.cloud-crud-sorting {
  background-color: transparent;
}


.cloud-crud-sorting + span button {
  margin-top: 0 !important;
}


.input-group .cloud-crud-sorting {
  border-right: 1px solid #ccc;
  border-radius: 0;
  cursor: pointer;
  font-size: 11px;
}

.input-group .cloud-crud-sorting.active {
  background-color: #cccccc;
}

.sc-table thead th input {
  min-width: 40px;
}

.sc-table thead th input,
.sc-table thead th select,
.sc-table thead th button {
  height: 25px;
  font-size: 11px;
}

.input-group-addon, .input-group-btn {
  vertical-align: top;
}

.input-group + .input-group {
  margin-top: 5px;
}

select option[disabled] {
  background-color: #CCCCCC;
}