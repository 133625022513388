.sc-mam-login {

  .login-container {

    .logo img {
      max-height: 115px;
    }

    display: table;
    height: 100%;
    width: 100%;

    .page-content {
      display: table-row;
      height: 100%;
    }

    .login-form {
      position: absolute;
      width: 320px;
      height: 350px;
      top: 50%;
      left: 50%;
      margin-left: -160px;
      margin-top: -175px;
    }

    .form-group {
      position: relative;
    }

    .form-control-icon-left {
      color: #333;
      display: block;
      height: 36px;
      line-height: 36px;
      pointer-events: none;
      position: absolute;
      left: 0;
      right: auto;
      text-align: center;
      top: 0;
      width: 36px;
      z-index: 2;
    }

    .form-control-icon-left + input {
      padding-left: 36px;
      padding-right: 12px;
    }

    .content-group {
      margin-bottom: 20px !important;
    }

    .display-block,
    label.display-block {
      display: block;
      margin-top: 5px;
    }

    .footer {
      left: 0;
      right: 0;
      text-align: center;
      bottom: 20px;
      position: absolute;
    }

  }
}

html, body {
  height: 100%;
}
