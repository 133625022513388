.cloudMessages {

  position: fixed;
  bottom: 0px;
  width: 100%;

  z-index: 9000;

  background-color: #263238;
  color: #ffffff;

  text-align: center;
}


.cloudMessages .alert {
  margin: 5px auto;
  margin-bottom: 0;
  padding: 8px;
  font-size: 80%;
  border-radius: 0;
}

.cloudMessages .alert:last-child {
  margin-bottom: 5px;
}

.cloudMessages .close-message {
  margin-left: 10px;
  font-size: 80%;
  color: red;
}

.cloudMessages .report-message {
  color: red;
  text-align: center;
  cursor: pointer;
}

.cloudMessages .fa {
  margin-left: 5px;
}