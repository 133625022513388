.cloud-file-search-input {

  position: relative;

  .thumb {
    height: 50px;
    margin-right: 3px;
    width: 50px;
    background-color: #EEEEEE;
    border: 1px solid #CCCCCC;
    display: block;
  }

  input {
    height: 50px;
    margin-left: 5px;
  }

  .input-group-addon i {
    padding: 11px;
    padding-left: 20px;
  }

  .input-group-addon i:hover {
    color: red;
  }

  .cloud-file-results {

    background-color: #FFFFFF;
    padding: 3px;
    border: 1px solid #CCCCCC;
    margin-left: 68px;
    z-index: 9999;

    li {
      padding: 5px;

      cursor: pointer;

      img {
        width: 50px;
        height: 50px;
        background-color: #EEEEEE;
        border: 1px solid #CCCCCC;
      }

    }

    li:hover {
      background-color: #CCCCCC;
    }

    .cloud-more-results {
      padding: 5px;
      border-top: 1px solid #333333;
    }

  }

}