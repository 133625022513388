@import "variables.scss";

.sc-mam-theme1 {

  overflow: hidden;

  a {
    color: #333;
    text-decoration: none;
  }

  .sc-cursor {
    cursor: pointer;
  }

  div:empty {
    display: none;
  }

  .panel {
    border-radius: 0;

    .panel-heading {
      border-radius: 0;
    }
  }

  .panel-primary {
    .panel-heading {
      background-color: $primaryColor;
    }
  }

  background-color: $backgroundColor;
  color: #333;
  font-family: $fontFamily;
  font-size: $fontSize;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;

  .h5, h5 {
    font-size: 17px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .tag-editor .tag-input {
    width: 100%;
  }

  .fa {
    cursor: pointer;
  }

  .fa.disabled {
    color: #c5c5c5;
    cursor: not-allowed;
  }

  .sc-page-container,
  .sc-body,
  .body-wrapper {
    height: 100%;
    width: 100%;
    display: block;
    position: relative;
  }

  .flex-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .sc-body {
    height: 100%;
  }

  .sc-body-wrapper,
  .sc-body-wrapper-bg {

    height: 100%;
    overflow: hidden;
    overflow-y: scroll;

    .sc-body-col-small {
      min-width: 300px;
      border-right: 1px solid #CCCCCC;
      -webkit-flex: 3 1 auto;
      flex: 3 1 300px;
    }

    .sc-body-col-big {
      width: 100%;
      -webkit-flex: 1 6 auto;
      flex: 1 6 auto;
      overflow-x: hidden;
    }

    .sc-body-col-big + .sc-body-col-small {
      border-left: 1px solid #CCCCCC;
    }

    .sc-row {
      flex-flow: row wrap;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }

    > .sc-row {

      margin-left: 20px;
      margin-right: 20px;

      &.sc-row-actions {
        height: $actionRowHeight;
      }

      &.sc-row-actions + .sc-row-content {
        margin-top: $naviTopMarginBottom;
        /* min-height: $contentHeight; */
      }

      > div + div {
        margin-left: 20px;
      }

      .sc-row-col4 {
        flex: 4;
      }

      &:first-child {
        margin-top: $naviTopMarginBottom;
      }

      &:last-child {
        margin-bottom: $contentMarginBottom;
      }

    }

    .sc-actions-wrapper {
      flex: 1 100%;

      .sc-actions {

        ul li {
          cursor: pointer;
          display: inline-block;
        }


        ul.dropdown-menu {
          min-width: 0;
        }

        ul.dropdown-menu li {
          margin-bottom: 1px;
          position: relative;
          display: block;
        }

        .sc-pagination {
          margin-right: 40px;
        }

      }

    }

    .sc-sidebar-left-wrapper {
      order: 1;
      padding: 10px;

      &:empty {
        display: none;
        padding: 0px;
      }

    }

    .sc-row-content > div {
      background-color: #FFF;
      border: 1px solid #dddddd;
    }

    .sc-content-wrapper {
      flex: 2 0px;
      order: 2;
      padding: 10px;

      &:empty {
        display: none;
        padding: 0px;
      }

      .sc-content-border {
        border-left: 1px solid #dddddd;
        border-right: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        border-top: 1px solid #ddd;
      }

    }

    .sc-crud-body {

      flex: 2 0px;
      order: 2;

      .sc-content-wrapper {
        padding: 0px;
      }

      .sc-table {
        border: none;
      }

      .panel {
        border: none;
        box-shadow: none;
      }

    }

    .sc-sidebar-right-wrapper {
      order: 3;
      padding: 10px;

      &:empty {
        display: none;
        padding: 0px;
      }

      .panel-heading {
        background-color: #FFFFFF;
        padding: 8px;
      }
    }

  }

  .sc-body-wrapper-bg {
    background-color: #ffffff;
    min-height: 100%;
    width: 100%;
    overflow: auto;
    display: -webkit-box;
    display: -webkit-inline-flex;
    display: -ms-flexbox;
    display: inline-flex;
    -webkit-flex-flow: row;
    flex-flow: row;
  }

  /* Too narrow to support three columns */
  @media all and (max-width: $maxWidthForResponsive) {
    .sc-body-wrapper-bg {
      -webkit-flex-flow: column;
      flex-direction: column;
    }
  }

  .sc-action-wrapper2 {
    background-color: #fdfdfd;
    border-bottom: 1px solid #CCCCCC;
    padding: 5px;
    min-height: 41px;
    clear: both;

    .pagination {
      height: 25px;

      li > a {
        border-radius: 0;
      }
    }

    .input-group-addon {
      border-radius: 0;
    }
  }

  .sc-content-wrapper2 {
    padding: 5px;
    clear: both;
    min-height: calc(100% - 41px);

    .pagination {
      height: 25px;

      li > a {
        border-radius: 0;
      }
    }

    .input-group-addon {
      border-radius: 0;
    }

    .sc-content-subactions {
      margin-bottom: 5px;
    }

  }

  .table td {
    vertical-align: middle;
  }

  .table {
    margin-bottom: 0;
  }

  .input-group-sm .pagination > li > a,
  .input-group-sm .pagination > li > span {
    padding: 4px 8px !important;
  }


  .drop-box {
    padding: 25px;
    text-align: center;
    vertical-align: middle;
  }

  .drop-box.dragover {
    background-color: #262B36;
    color: #FFF;
  }

  .drop-box .drop-spacer {
    border-bottom: 1px dotted #333333;
    height: 0;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
  }

  .login-container .sc-body {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }


  .sc_entries_search  {
    width: 100%;
    margin: 10px auto;
    padding: 5px;
    box-shadow: 0px 0px 5px #eeeeee;

    .form-control {
      border: none;
      box-shadow: none;
    }

    .input-group-addon {
      background-color: transparent;
      border: none;
    }

  }

  .sc_entries_search.no_border {
    box-shadow: none;
    border: 0 ;
  }

  tr.weekend {
    background-color: #666;
    color: #fff;
  }

  tr.warning {
    color: #000;
  }


  @import "custom/form.scss";
  @import "custom/table.scss";
  @import "custom/naviLeft.scss";
  @import "custom/tree.scss";
  @import "custom/basket.scss";
  @import "custom/fileGrid.scss";
  @import "custom/fileEdit.scss";
  @import "custom/filesearchInputDirective.scss";
  @import "custom/bootstrap.scss";
  @import "custom/trix.scss";

}

@import "custom/loadingscreen.scss";
@import "custom/messages.scss";
@import "custom/context.scss";

.clearfix {
  display: inline !important;
}

