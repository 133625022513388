.sc-file-grid-element {

  .thumbnail {

    cursor: pointer;
    padding: 3px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 0;
    -webkit-transition: border .2s ease-in-out;
    -o-transition: border .2s ease-in-out;
    transition: border .2s ease-in-out;
    box-shadow: 0 1px 1px rgba(0,0,0,.05);

    .thumb {
      position: relative;
      display: block;

      .grid-img-box {

        display: inline-block;
        width: 100%;
        max-width: 100%;

        img {
          display: inline-block;
          width: 100%;
          max-width: 100%;
          height: auto;
        }

      }

      .tags {
        display: none;
        overflow: hidden;
        text-overflow: ellipsis;
        position: absolute;
        bottom: 0;
        left: 0%;
        padding: 5px;
        padding-bottom: 0;
        opacity: 0.5;
        width: 100%;

        span {
          margin: 2px;
        }

      }
    }

    &:hover  {
      background-color: #CCCCCC;
    }

    .caption {
      padding: 10px 5px 10px;
      height: 30px;

      h6 {
        margin-top: 0!important;
        font-weight: bold;
        height: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 0;
        text-align: center;
      }

    }

  }

}