.sc-table {

  margin: 0;

  border: 1px solid #dddddd;

  .text-right {
    text-align: right;
  }

  thead {

    td,
    th {
      outline: 0 none;
      position: relative;
      border-bottom: 1px solid #dddddd;

      .btn {
        margin-top: -2px;
      }

    }

    th select {
      font-weight: normal;
      padding: 3px;
      width: 100%;
    }

    th input {
      font-weight: normal;
      width: 100%;
    }

    .sorting,
    .sorting_asc,
    .sorting_desc {
      cursor: pointer;
    }

    .sorting,
    .sorting_asc,
    .sorting_asc_disabled,
    .sorting_desc,
    .sorting_desc_disabled {
      padding-right: 8px;
    }

    .sorting_asc::after,
    .sorting_desc::after {
      content: "";
      display: inline-block;
      font-family: FontAwesome;
      font-size: 12px;
      margin: 0 5px;
    }

    .sorting_asc::after {
      content: "\f107";
    }

    .sorting_desc::after {
      content: "\f106";
    }

    .sorting::after {
      color: #999;
      margin-top: -10px;
    }

  }

  tbody {
    .input-group {
      height: 32px;
    }
    td {
      text-overflow: ellipsis;
    }
    tr:hover,
    tr.active td {
      background-color: $primaryColorHover;
    }
  }

  .sc-tables_empty {
    text-align: center;
  }

}

.sc-tables_wrapper {
  position: relative;
}

.sc-tables_wrapper::after {
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}

.sc-tables_wrapper + .panel-body {
  border-top: 1px solid #ddd;
}

.sc-tables_processing {
  background: rgba(0, 0, 0, 0) linear-gradient(to right, rgba(255, 255, 255, 0) 0px, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%) repeat scroll 0 0;
  height: 40px;
  left: 50%;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
}

.sc-table-footer, .sc-table-header {
  padding-top: 20px;
}

.sc-table-footer::after, .sc-table-header::after {
  clear: both;
  content: "";
  display: table;
}

.sc-table-footer > div:first-child, .sc-table-header > div:first-child {
  margin-left: 0;
}

.sc-table-header {
  border-bottom: 1px solid #ddd;
}

.sc-table-footer {
  border-top: 1px solid #bbb;
}


.table-small {
  font-size: 12px;
}