.caret, label {
}

.sc_readonly {
  width: 100%;
  padding: 3px;
  background-color: #EEE;
  border: 1px solid #CCC;
  cursor: not-allowed;
}

.cloud-language-input-group {
  position: relative;
}

.cloud-language-input-group .form-control {
  padding-left: 35px;
}

.cloud-language-input-group textarea {
  min-height: 200px;
}

.cloud-language-input-group .btn {
  border-radius: 0;
}

cloud-language-flag {
  position: absolute;
  left: 10px;
  top: 5px;
  z-index: 100;
}

.btn-tabs button {
  margin-bottom: 5px;
  text-align: left;
  width: 100%;
}

.panel-body fieldset {
  border: none;
  border-top: 1px solid #ccc;
  padding: 15px;
  margin-bottom: 25px;

  legend {
    font-size: 16px;
    text-decoration: none;
    border-bottom: 0;
    padding-left: 5px;
    width: auto;
    padding-right: 5px;
    margin-bottom: 0px;
  }
}

.panel-body .row .col fieldset{
  height: 100%;
}