.dropdown-menu {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  color: #333;
  min-width: 180px;
  padding: 5px 0;
  border-radius: 0;

  a {
    cursor: pointer;
  }

}

.dropdown-menu .divider {
  margin: 5px 0;
}

.dropdown-menu > li {
  margin-bottom: 1px;
  position: relative;
}

.dropdown-menu > .checkbox-switchery.switchery-sm, .dropdown-menu > .checkbox-switchery.switchery-xs, .dropdown-menu > li:last-child {
  margin-bottom: 0;
}

.dropdown-menu > li > a {
  padding: 8px 15px;
}

.dropdown-menu > li > a > i, .dropdown-menu > li > a > img {
  margin-right: 10px;
}

.dropdown-menu > li > a > i.pull-right, .dropdown-menu > li > a > img.pull-right {
  margin-left: 10px;
  margin-right: 0;
  margin-top: 3px;
}

.dropdown-menu > li > a > img {
  max-height: 16px;
}

.dropdown-menu > li > a > .badge, .dropdown-menu > li > a > .label {
  margin-top: 1px;
}

.dropdown-menu > li > label:focus, .dropdown-menu > li > label:hover {
  background-color: #f5f5f5;
  color: #333;
  text-decoration: none;
}

.dropdown-menu > .active > label, .dropdown-menu > .active > label:focus, .dropdown-menu > .active > label:hover {
  background-color: #2196f3;
  color: #fff;
  outline: 0 none;
}

.dropdown-menu > .disabled > label, .dropdown-menu > .disabled > label:focus, .dropdown-menu > .disabled > label:hover {
  background-color: transparent;
  color: #999;
}

.dropdown-menu > li > label {
  cursor: pointer;
  display: block;
  padding: 8px 15px 8px 43px;
}

.dropdown-menu > li > label .checker, .dropdown-menu > li > label .choice, .dropdown-menu > li > label > input[type="checkbox"], .dropdown-menu > li > label > input[type="radio"] {
  left: 15px;
  margin-top: 1px;
  top: auto;
}

.dropdown-menu > li.checkbox, .dropdown-menu > li.radio {
  margin-top: 0;
}

.dropdown-menu > li.checkbox-right > label, .dropdown-menu > li.radio-right > label {
  padding-left: 15px;
  padding-right: 43px;
}

.dropdown-menu > li.checkbox-right > label .checker, .dropdown-menu > li.checkbox-right > label .choice, .dropdown-menu > li.checkbox-right > label > input[type="checkbox"], .dropdown-menu > li.checkbox-right > label > input[type="radio"], .dropdown-menu > li.radio-right > label .choice, .dropdown-menu > li.radio-right > label > input[type="radio"] {
  left: auto;
  right: 15px;
}

.dropdown-menu > .checkbox-switchery > label > .switchery {
  left: 15px;
}

.dropdown-menu > .checkbox-switchery.checkbox-right[class*="switchery-"] > label {
  padding-left: 15px;
}

.dropdown-menu > .checkbox-switchery.checkbox-right[class*="switchery-"] > label > .switchery {
  left: auto;
  right: 15px;
}

.dropdown-menu > .checkbox-switchery.switchery-sm > label {
  padding-left: 68px;
}

.dropdown-menu > .checkbox-switchery.switchery-xs > label {
  padding-left: 60px;
}

.dropdown-menu > .checkbox-switchery.checkbox-right.switchery-sm > label {
  padding-right: 68px;
}

.dropdown-menu > .checkbox-switchery.checkbox-right.switchery-xs > label {
  padding-right: 60px;
}

.dropdown-menu > .disabled .badge, .dropdown-menu > .disabled .label, .dropdown-menu > .disabled img {
  opacity: 0.8;
}

.dropdown-menu[class*="bg-"] > li > a, .dropdown-menu[class*="bg-"] > li > label {
  color: #fff;
}

.dropdown-menu[class*="bg-"] > li > a:focus, .dropdown-menu[class*="bg-"] > li > a:hover, .dropdown-menu[class*="bg-"] > li > label:focus, .dropdown-menu[class*="bg-"] > li > label:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.dropdown-menu[class*="bg-"] > li > a > .badge, .dropdown-menu[class*="bg-"] > li > a > .label, .dropdown-menu[class*="bg-"] > li > label > .badge, .dropdown-menu[class*="bg-"] > li > label > .label {
  background-color: #fff;
  border-color: #fff;
  color: #333;
}

.dropdown-menu[class*="bg-"] > .active > a, .dropdown-menu[class*="bg-"] > .active > a:focus, .dropdown-menu[class*="bg-"] > .active > a:hover, .dropdown-menu[class*="bg-"] > .active > label, .dropdown-menu[class*="bg-"] > .active > label:focus, .dropdown-menu[class*="bg-"] > .active > label:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.dropdown-menu[class*="bg-"] > .disabled > a, .dropdown-menu[class*="bg-"] > .disabled > a:focus, .dropdown-menu[class*="bg-"] > .disabled > a:hover, .dropdown-menu[class*="bg-"] > .disabled > label, .dropdown-menu[class*="bg-"] > .disabled > label:focus, .dropdown-menu[class*="bg-"] > .disabled > label:hover {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.6);
}

.dropdown-menu[class*="bg-"] > .dropdown-header {
  color: rgba(255, 255, 255, 0.6);
}

.dropdown-menu[class*="bg-"] > .dropdown-header.highlight {
  background-color: rgba(0, 0, 0, 0.1);
}

.dropdown-menu[class*="bg-"] .divider {
  background-color: rgba(255, 255, 255, 0.4);
}

.dropdown-menu-lg > li > a {
  font-size: 14px;
  line-height: 1.42857;
  padding-bottom: 9px;
  padding-top: 9px;
}

.dropdown-menu-sm > li > a {
  padding-bottom: 6px;
  padding-top: 6px;
}

.dropdown-menu-xs > li > a {
  font-size: 12px;
  line-height: 1.66667;
  padding-bottom: 5px;
  padding-top: 5px;
}

.dropdown-menu > .dropdown-submenu > a {
  padding-right: 38px;
  position: relative;
}

.dropdown-menu > .dropdown-submenu > a::after {
  content: "î§";
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  margin-top: -8px;
  opacity: 0.8;
  position: absolute;
  right: 15px;
  top: 50%;
}

.dropdown-content-heading, .label, .menu-heading {
  font-weight: 500;
  text-transform: uppercase;
}

.dropdown-menu > .dropdown-submenu:focus > a, .dropdown-menu > .dropdown-submenu:hover > a {
  background-color: #f5f5f5;
}

.dropdown-menu > .dropdown-submenu:focus > a::after, .dropdown-menu > .dropdown-submenu:hover > a::after {
  opacity: 1;
}

.dropdown-menu > .dropdown-submenu.active > a {
  background-color: #2196f3;
  color: #fff;
}

.dropdown-menu > .dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-menu > .dropdown-submenu.disabled > .dropdown-menu {
  display: none;
}

.dropdown-menu > .dropdown-submenu.disabled > a {
  background-color: transparent;
}

.dropdown-menu > .dropdown-submenu > .dropdown-menu {
  left: 100%;
  margin-top: -6px;
  top: 0;
}

.dropup .dropdown-menu > .dropdown-submenu > .dropdown-menu, .dropup.dropdown-menu > .dropdown-submenu > .dropdown-menu, .navbar-fixed-bottom .dropdown .dropdown-menu > .dropdown-submenu > .dropdown-menu {
  bottom: 0;
  margin-bottom: -6px;
  margin-top: 0;
  top: auto;
}

.dropdown-menu > .dropdown-submenu.dropdown-submenu-left > .dropdown-menu {
  left: auto;
  right: 100%;
}

@media (max-width: 768px) {
  .dropdown-menu > .dropdown-submenu {
    position: static;
  }

  .dropdown-menu > .dropdown-submenu > a::after {
    content: "î§";
  }

  .dropdown-menu > .dropdown-submenu .dropdown-menu, .dropdown-menu > .dropdown-submenu.dropdown-submenu-left .dropdown-menu {
    border-color: rgba(0, 0, 0, 0.1);
    border-width: 0;
    box-shadow: none;
    float: none;
    left: 0;
    margin: 0;
    min-width: 100%;
    position: relative;
    right: 0;
  }

  .dropdown-menu > .dropdown-submenu .dropdown-menu > li > a, .dropdown-menu > .dropdown-submenu.dropdown-submenu-left .dropdown-menu > li > a {
    padding-left: 30px;
  }

  .dropdown-menu > .dropdown-submenu .dropdown-menu > li > ul > li > a, .dropdown-menu > .dropdown-submenu.dropdown-submenu-left .dropdown-menu > li > ul > li > a {
    padding-left: 45px;
  }
}

.dropdown-menu[class*="bg-"] > .dropdown-submenu:focus > a, .dropdown-menu[class*="bg-"] > .dropdown-submenu:hover > a {
  background-color: rgba(0, 0, 0, 0.1);
}

.dropdown-menu[class*="bg-"] > .dropdown-submenu.disabled:focus > a, .dropdown-menu[class*="bg-"] > .dropdown-submenu.disabled:hover > a {
  background-color: transparent;
}

.dropdown-header {
  color: #999;
  font-size: 11px;
  line-height: 1.82;
  margin-top: 10px;
  padding: 8px 15px;
  text-transform: uppercase;
}

.dropdown-header:first-child {
  margin-top: 5px;
}

.dropdown-header.highlight {
  background-color: #f8f8f8;
  color: #999;
  margin-top: 0;
}

.dropdown-header.highlight + li, li + .dropdown-header.highlight {
  margin-top: 6px;
}

.dropdown-header.highlight:first-child {
  margin-top: 0;
}

.dropdown-header > i {
  margin-right: 10px;
}

.dropdown-header > i.pull-right {
  margin-left: 10px;
  margin-right: 0;
  margin-top: 3px;
}