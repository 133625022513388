
.sc-navi-left {

  header {

    background-color: $naviLeftBgColor;
    box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.1) inset, 0 -1px 0 rgba(255, 255, 255, 0.1) inset;
    height: 45px;
    width: 58px;
    padding-left: 10px;
    padding-top: 4px;

    img {
      width: 35px;
    }

  }

  .nav_spacer {
    width: 100%;
    height: 1px;
    box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.1) inset, 0 -1px 0 rgba(255, 255, 255, 0.1) inset;
  }

  background-color: $naviLeftBgColor;
  color: $naviLeftTextColor;

  display: block;
  height: 100%;
  width: $naviLeftWidth;
  z-index: 99;
  position: relative;

  ul li + li {
    margin-top: 1px;
  }

  ul li a {
    color: rgba(255, 255, 255, 0.75);
    display: block;
    transition: background 0.15s linear 0s, color 0.15s linear 0s;
  }

  ul li a:focus, ul li a:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: #fff;
  }

  ul li a > i {
    float: left;
    margin-right: 15px;
    margin-top: 2px;
    top: 0;
    transition: opacity 0.2s ease-in-out 0s;
  }

  ul li a > i.pull-right {
    margin-left: 15px;
    margin-right: 0;
  }

  ul li.disabled > a, ul li.disabled > a:focus, ul li.disabled > a:hover {
    background-color: transparent;
    color: inherit;
    cursor: not-allowed;
    opacity: 0.3;
  }

  ul li.nav-divider {
    background-color: rgba(255, 255, 255, 0.1);
    height: 1px;
    margin: 10px 0;
  }

  .sidebar-default ul li.nav-divider {
    background-color: #eee;
  }

  ul li:not(.active) .label-transparent {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 400;
  }

  ul > li > a {
    font-weight: 500;
    min-height: 44px;
    padding: 12px 20px;
    text-decoration: none;
  }

  ul > li.active > a, ul > li.active > a:focus, ul > li.active > a:hover {
    background-color: #455a64;
    color: #fff;
  }

  ul > li.active > a > [class*="text-"] {
    color: #fff;
  }

  ul > li.active > a .badge, ul > li.active > a .label {
    background-color: transparent;
    border-color: transparent;
    color: #fff;
    transition: background-color 0.2s ease-in-out 0s;
  }

  ul > li ul {
    background-color: rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);
    list-style: outside none none;
    margin: 0;
    padding: 0;
  }

  ul > li ul li a {
    padding: 5px;
    padding-left: 20px;
  }

  ul > li ul li a i {
    margin-right: 0;
  }

  ul > li > ul > li > ul > li > a {
    padding-left: 71px;
  }

  ul > li > ul > li > ul > li > ul > li > a {
    padding-left: 91px;
  }

  ul > li > ul li:first-child {
    padding-top: 10px;
  }

  ul > li > ul li:last-child {
    padding-bottom: 10px;
  }

  ul > li > ul li.active > a, ul > li > ul li.active > a:focus, ul > li > ul li.active > a:hover {
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff;
  }

  ul .hidden-ul {
    display: none;
  }

  ul >li:hover>ul {
    display: block!important;
  }

  ul,
  ul li {
    position: relative;
  }

  ul > li > a {
    display: block;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    min-height: 44px;
    font-weight: 500;
    cursor: pointer;
  }

  ul > li > a > i {
    margin: 2px 0;
    display: block;
    float: none;
    font-size: $naviLeftIconSize;
  }

  ul > li > a > span {
    display: none;
    position: absolute;
    top: 0;
    right: -260px;
    background-color: #455A64;
    border-color: #455A64;
    padding: inherit;
    padding-left: 20px;
    padding-right: 20px;
    width: 260px;
    text-align: left;
    color: #fff;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 3px;
  }

  ul > li.with2cols > a > span {
    right: -520px;
    width: 520px;
  }

  ul > li.with3cols > a > span {
    right: -780px;
    width: 780px;
  }

  ul > li:hover > a > span {
    display: inline;
  }

  ul > li > a > span:after {
    content: "";
    width: 0;
    height: 0;
    border-right: 5px solid;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right-color: inherit;
    position: absolute;
    left: -5px;
    top: 50%;
    margin-top: -5px;
  }

  ul > li > ul {
    position: absolute;
    right: -260px;
    top: 44px;
    width: 260px;
    display: none;
    background-color: #263238;
    border-left: 1px solid rgba(255,255,255,.1);
    border-radius: 0 0 3px;
  }

  ul > li > ul.with2cols {
    position: absolute;
    right: -520px;
    width: 520px;
    margin-bottom: 5px;

    .col {
      padding: 0;
      width: 50%;
      float: left;
    }

  }

  ul > li > ul.with3cols {

    position: absolute;
    right: -780px;
    width: 780px;
    margin-bottom: 5px;

    .col {
      padding: 0;
      width: 33%;
      float: left;
    }

  }

  ul > li > ul > li div {
    margin-bottom: 5px;
  }

  ul > li > ul > li > a {
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;
    cursor: pointer;
  }


  ul > li > ul > li > a i {
    display: inline;
  }
}

