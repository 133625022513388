.sc-basket {

  width: 250px;
  border: 1px solid #dddddd;

  table > tbody > tr td:nth-child(2) {
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

}